import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root'
})

export class GlobalService {
    private globalVar: string = '';

    constructor() { }

    setGlobalVar(key:string, val: any) {
        this[key] = val;
    }

    getGlobalVar(key:string) {
        return this[key];
    }
}