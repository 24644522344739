import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public platform: Platform) {
    this.platform.backButton.subscribeWithPriority(1, () => {
      // 在整个应用程序上禁用硬件后退按钮
    });
  }
}
