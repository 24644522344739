import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [  
  
  {
    path: '',
    loadChildren: () => import('./page/welcome/welcome.module').then( m => m.WelcomePageModule)
  },  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  }, {
    path: 'page',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./page/login/login.module').then(m => m.LoginPageModule)
      }, {
        path: 'signup',
        loadChildren: () => import('./page/signup/signup.module').then(m => m.SignupPageModule)
      }, {
        path: 'selectbranch',
        loadChildren: () => import('./page/selectbranch/selectbranch.module').then(m => m.SelectbranchPageModule)
      }, {
        path: 'myqrcode',
        loadChildren: () => import('./page/myqrcode/myqrcode.module').then(m => m.MyqrcodePageModule)
      }
    ],
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
