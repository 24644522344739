interface en{
  prod:any,
  dev:any
}
export class appRestful {
  // 接口用户名
  static username:string = 't4apiadmin';//'test';
  // 接口密码
  static password:string = 'M1gr8tion9527!';//'1234';

  static env:string = 'prod'; // dev

  static url:en = {
    prod:{url:'https://order-api.t4.co.uk'},
    dev:{url:'https://staging-order-api.t4.co.uk'}
  }

  //生产环境URL
  public static getProdUrl() {
    let url = this.url;
    if(this.env == 'prod'){
      return url.prod.url; //正试
    }else{
      return url.dev.url; //测试
    }
    // return 'https://staging-order-api.t4.co.uk';//测试
    // return 'https://order-api.t4.co.uk'正试
  }
  //积分卡前缀URL
  public static getLoyalcardUrl() {
    return 'https://order.t4.co.uk/img/loyalcard/';
  }
  // 支付框架地址前缀
  public static getPaydUrl() {
    return 'https://order.t4.co.uk/apppayment'; // 正式
   // return 'https://staging-order.t4.co.uk/apppayment'; // 测试
  }
  // 获得图片前缀地址
  public static getTeaImgUrl(){
    return 'https://order.t4.co.uk/image/getfile?filename='; 
  }
  // 获得token
  public static getAuthorization(): string {
    return btoa(this.username + ":" + this.password);
  }
}