import { Injectable } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { StorageServic } from './storage/storage.service';
import { appRestful } from './appResful';
import { HttpserviceService } from './httpService';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(
    public httpService: HttpserviceService,
    public router: Router,
    public StorageServic: StorageServic,
    public alertController: AlertController
  ) { }
  in_array(search, array) {
    for (var i in array) { if (array[i] == search) { return true; } } return false;
  }
  nowCategoryList(): any {
    //去重
    let list: any = this.StorageServic.read('CategoryList') || [];
    let tmp = [];
    let result = list.filter((v) => {
      if (this.in_array(v.guid, tmp)) {
        return false;
      } else {
        tmp.push(v.guid);
        return true;
      }
    });
    return result;
  }
  setCategoryList(val) {
    this.StorageServic.write('CategoryList', val);
  }
  cleanCategoryList() {
    this.StorageServic.remove('CategoryList');
  }
  getAllProduct(): any {
    return this.StorageServic.read('AllProduct');
  }
  setAllProduct(val) {
    this.StorageServic.write('AllProduct', val);
  }
  cleanAllProduct() {
    this.StorageServic.remove('AllProduct');
  }


  // 获取当前分店分类
  async ListCategory(accountRef: string, branchRef: string, callback) {
    //显示等待样式
    let uri = "/AppMenu/ListCategory/" + accountRef + '/' + branchRef;
    let result = await this.httpService.httpGet2(uri, {});
    this.setCategoryList(result);
    callback && callback(result);
  }

  // 获取分类产品
  async ListProduct(accountRef: string, branchRef: string, category_guid: string, callback) {
    let uri = "/AppMenu/ListProduct/" + accountRef + '/' + branchRef + '/' + category_guid;
    let result = await this.httpService.httpGet2(uri, {});
    callback && callback(result);
  }

  // 获取全部产品
  async FetclAllProduct(accountRef: string, branchRef: string, callback) {
    let uri = "/AppMenu/ListAllProduct/" + accountRef + '/' + branchRef;
    let result = await this.httpService.httpGet2(uri, {});
    for (let o in result) {
      for (let oo in result[o]['products']) {
        result[o]['products'][oo]['srcFull'] = appRestful.getTeaImgUrl() + result[o]['products'][oo]['productImages'][0];
      }
    }
    this.setAllProduct(result);
    callback && callback(result);
  }
  // 筛选分类产品
  showProductList(guid) {
    let data = this.getAllProduct();
    for (let i in data) {
      if (data[i]['guid'] == guid) {
        return data[i]['products'];
      }
    }
    return [];
  }
  // 按产品分类名称选择产品
  showProductListByName(name:string) {
    let data = this.getAllProduct();
    for (let i in data) {
      if (data[i]['name'] == name) {
        return data[i]['products'];
      }
    }
    return [];
  }
  // 查找指定产品
  getProductFromALl(guid): any {
    let data = this.getAllProduct();
    for (let i in data) {
      for (let ii in data[i]['products']) {
        if (guid == data[i]['products'][ii]['guid']) {
          return data[i]['products'][ii];
        }
      }
    }
    return null;
  }
  // 根据dishRef取产品
  getProductFromDishRef(dishRef):any{
    let data = this.getAllProduct();
    for (let i in data) {
      for (let ii in data[i]['products']) {
        if (dishRef == data[i]['products'][ii]['dishRef']) {
          return data[i]['products'][ii];
        }
      }
    }
    return null;
  }

  // 根据产品的Ref编号获得产品
  async FindProductByRef(accountRef: string, branchRef: string, productRef: string, callback) {
    //AppMenu
    let uri = "/AppMenu/FindProductByRef/" + accountRef + '/' + branchRef + '/' + productRef;
    let result = await this.httpService.httpGet2(uri, {});
    callback && callback(result);
  }

  // 根据产品guid获取信息
  async FindProductByGuid(guid) {
    return this.httpService.httpGet2("/AppMenu/FindProductByGuid/" + guid, {});
  }
  // 输出产品图标，图片
  echoImg(Ref) {
    const iconObj = {
      'M1': 'icon-size-regular',
      'L2': 'icon-size-large',

      'B1': 'icon-tea-black',
      'O2': 'icon-tea-onlong',
      'G3': 'icon-tea-green-hot',
      'G5': 'icon-tea-green-cold',
      'R4': 'icon-tea-toyal',

      'I1': 'icon-ice-no',
      'I2': 'icon-ice30',
      'I3': 'icon-ice50',
      'I4': 'icon-ice100',
      'I7': 'icon-ice120',
      'I5': 'icon-ice-warm',

      'S1': 'icon-sugar-no',
      'S2': 'icon-sugar30',
      'S3': 'icon-sugar50',
      'S4': 'icon-sugar100',
      'S5': 'icon-sugar120',

      'P1': 'icon-ext-pearl',
      'A2': 'icon-ext-aloe',
      'C3': 'icon-ext-coconut-jelly',
      'G4': 'icon-ext-grass-jelly',
      'O5': 'icon-ext-oat',
      'C6': 'icon-ext-coffe-jelly',
      'P7': 'icon-ext-pudding',
      'R8': 'icon-ext-red-bean',
      'W9': 'icon-ext-white-pearl',
      'O3': 'icon-ext-oreo',
      'C4': 'icon-ext-cream',
      'GTJ1':'icon-ext-tea-jelly',
      'M16':'icon-ext-mango-pearl',
      

      'FN1' : 'icon-snack-non-spicy',
      'FN2' : 'icon-snack-spicy',

      'A5' : 'icon-aloe-no-aloe',

      'FM1': 'icon-milk-fresh',
      'SM2': 'icon-milk-soy',
      'OM3': 'icon-milk-oat',
      'VS4':'icon-milk-vitasoy',

      'BS1': 'icon-brown-sugar-biscuits',
    }
    return iconObj[Ref] ? iconObj[Ref] : 'icon-size-regular';
  }
}
