import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { UtilService } from '../../services/util.service';
import { ToastController, NavController, AlertController, IonContent, IonItem } from '@ionic/angular';

@Component({
  selector: 'app-product-setting',
  templateUrl: './product-setting.component.html',
  styleUrls: ['./product-setting.component.scss'],
})
export class ProductSettingComponent implements OnInit {

  public productInfo: any = {};
  public teaImage: string = '';
  public TotalPrice: number = 0;
  public edit: boolean = false;
  public buyNumber: number = 1;
  constructor(
    private MenuService: MenuService,
    private toastController: ToastController,
    private alertController: AlertController,
    private UtilService: UtilService,
  ) { }
  @Input()
  childData: any;

  @Output()
  onUpdate = new EventEmitter<any>();//数据改变通知


  ngOnInit() {
    console.log(this.childData)

    this.productInfo = { ...this.childData.data };
    if( this.productInfo.guid == this.childData.item.selected.guid){
      this.productInfo = { ...this.childData.item.selected};
    }else{
      this.productInfo.multipleChoice = JSON.parse(this.productInfo.multipleChoice);
      this.teaImage = 'https://order.t4.co.uk/image/getfile?filename=' + this.productInfo['productImages'][0];
      this.productInfo['multipleChoice'].map((n) => {
        n.Options.map((n2) => {
          Object.assign(n2, {
            selected: n2.ExtraFeature.IsSelected,
            selectQty: parseInt(n2.ExtraFeature.IsSelected ? n2.ExtraFeature.DefaultQty : 0),
            MaxQty: n2.ExtraFeature.MaxQty ? n2.ExtraFeature.MaxQty : 3,
          });
        });
      });
    }
    this.TotalPrice = this.getTeaPrice();
  }

  ngAfterViewInit() {
  }

  echoImg(Ref) {
    return this.MenuService.echoImg(Ref);
  }

  getTeaPrice(): number {
    let price = this.productInfo['salePrice_IncTax'];
    this.productInfo['multipleChoice'].forEach((m) => {
      m.Options.forEach(o => {
        if (o.selected) {
          price = price + o.Price_EatIn * o.selectQty;
        }
      });
    });
    let basePrice = this.childData.item.basePrice;
    if(this.childData.type == "product" ){
      let i = this.childData.i;
      let priceb = this.childData.item.items[i]['price'];
      if( priceb ){
        basePrice = priceb;
      }
      return Math.round((price - basePrice) * 100 * this.buyNumber) / 100;
    }else if(this.childData.type == "categories" ){
      if(this.childData.cate.basePrice){
        basePrice = this.childData.cate.basePrice;
      }
      return Math.round((price - basePrice) * 100 * this.buyNumber) / 100;
    }
  }

  // 单击事件,检查是单选则点中说选中，多选的话取反,数量归0
  // 产品ID, 项目id, 数量, 直达, 大项序号， 小项序号
  selectItem(id, Ref, number, direct, i, n) {
    //满足条件则弹出层
    let element = this.productInfo['multipleChoice'][i];
    let element2 = element['Options'][n];
    for (const iterator in element['Options']) {
      if (element['IsSingleSelection']) { // 单选
        if (iterator == n) {
          if (element['IsRequired']) { // 必选项不能取消
            this.productInfo['multipleChoice'][i]['Options'][n]['selected'] = true;
            this.productInfo['multipleChoice'][i]['Options'][n]['selectQty'] = 1;
          } else {
            this.productInfo['multipleChoice'][i]['Options'][n]['selected'] = !this.productInfo['multipleChoice'][i]['Options'][n]['selected'];
            this.productInfo['multipleChoice'][i]['Options'][n]['selectQty'] = this.productInfo['multipleChoice'][i]['Options'][n]['selected'] ? 1 : 0;
          }
        } else {
          this.productInfo['multipleChoice'][i]['Options'][iterator]['selected'] = false;
          this.productInfo['multipleChoice'][i]['Options'][iterator]['selectQty'] = 0;
        }
      } else {// 多选
        if (iterator == n) {
          if (direct) { // 直达选择
            //先检测总数
            let checkNum = 0;
            for (let iop of element.Options) {
              checkNum = checkNum + iop.selectQty;
            }
            if (checkNum >= 3) {
              this.maxTop();
              return;
            }
            this.productInfo['multipleChoice'][i]['Options'][n]['selectQty']++;
            this.productInfo['multipleChoice'][i]['Options'][n]['selected'] = this.productInfo['multipleChoice'][i]['Options'][n]['selectQty'] ? 1 : 0;
          } else { // 数量加减
            this.productInfo['multipleChoice'][i]['Options'][n]['selectQty'] = number;
            this.productInfo['multipleChoice'][i]['Options'][n]['selected'] = (this.productInfo['multipleChoice'][i]['Options'][n]['selectQty'] > 0);
          }
        }
      }
    }
    this.TotalPrice = this.getTeaPrice();
    return;
  }
  // 清除选项,设置选项为0
  clearItem(id, Ref, number, direct, i, n) {
    this.selectItem(id, Ref, 0, direct, i, n);
  }

  // 弹窗
  maxTop() {
    this.toastController.create({
      message: 'Exceed Max 3 Toppings',
      color: 'favorite',
      mode: 'ios',
      position: 'middle',
      duration: 1000
    }).then(toast => {
      toast.present();
    });
    return;
  }
  // 数据回传到购物车配置页
  pushToCart() {

    for (const key in this.productInfo['multipleChoice']) {
      if (this.productInfo['multipleChoice'].hasOwnProperty(key)) {
        const element = this.productInfo['multipleChoice'][key];
        let pass = !element['IsRequired'];
        let selectNum = 0;
        for (const key2 in this.productInfo['multipleChoice'][key]['Options']) {
          if (this.productInfo['multipleChoice'][key]['Options'].hasOwnProperty(key2)) {
            const element2 = this.productInfo['multipleChoice'][key]['Options'][key2];
            if (this.productInfo['multipleChoice'][key]['Options'][key2]['selected']) {
              pass = true;
              selectNum = selectNum + 1;
            }
          }
        }
        if (!pass) {
          this.UtilService.myToast(element.Name + ' must be selected.');
          return;
        }

        if (element['MultiSelectionMaxLimit'] && selectNum > element['MultiSelectionMaxLimit']) {
          this.UtilService.myToast(element.Name + ' out of maxLimit');
          return;
        }
      }
    }

    //向父组件传递事件
    this.onUpdate.emit({ number: this.buyNumber, goods: this.productInfo, price: this.TotalPrice, data: this.childData });
  }

}
