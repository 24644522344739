import { Component, OnInit, AfterViewInit, Input, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { HappyHourService } from '../../services/happyHour.service';
import { StorageServic } from '../../services/storage/storage.service';
import * as moment from 'moment';
import { GlobalService } from '../../services/global.service';


@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})

export class CountdownComponent implements OnInit, AfterViewInit {

  private Hours: string = '00';
  private Minutes: string = "00";
  private Seconds: string = "00";

  private prevewServerTime:any; // 上次接口时间
  private nowTimeObj: any; // 运行时钟对象
  private startTimeObj: any; // 开始时钟对象
  private endTimeObj: any; // 结束时钟对象
  private isInited: boolean = false; // 环境初始化状态
  private runing: string = 'waitRun';// ['waitRun', 'running', 'runEnd'];// 运行状态
  private happStatu: string = 'stop';// ['run', 'stop2run', 'run2top', 'stop']; // 状态变化

  private inShow: boolean = false;

  @Input("background")
  background: any = '#ffff';//背景色

  @Input("happyHourStatus")
  happyHourStatus: string;//运行状态


  private isEnabled: boolean; // 是否开启
  private isStarted: boolean; // 是否开始
  private startTime: string;  // 开始时间
  private endTime: string;    // 结束时间
  private serverTime: string; // 服务器时间

  @Output('setHappyEvent')
  setHappyEvent = new EventEmitter()

  @Output()
  happyHourStatusChange = new EventEmitter();

  private TimerHandle = null;

  constructor(
    private StorageServic: StorageServic,
    private HappyHourService: HappyHourService,
    private GlobalService:GlobalService
  ) {
    // 创建组件时调用
  }

  ngOnInit() { }

  time2towchr(n = 0): string {
    const n2 = '0' + n.toString();
    return <string>n2.slice(-2) || n2;
  }


  // 设置倒计时分秒为显示所用
  // 跳动, 页面计时器读数刷新
  setCountDown(sec) {
    var hours = Math.floor(sec / 3600); hours = hours > 0 ? hours : 0;
    var minutes = Math.floor((sec - (hours * 3600)) / 60); minutes = minutes > 0 ? minutes : 0;
    var seconds = sec % 60; seconds = seconds > 0 ? seconds : 0;
    this.Hours = this.time2towchr(hours);
    this.Minutes = this.time2towchr(minutes);
    this.Seconds = this.time2towchr(seconds);
  }
  // 倒计时跳动
  // 1.更新本地时钟，2.增加时钟秒数，3.刷新页面显示
  runTimer(): void {
    if (this.isInited) {
      this.nowTimeObj.add(1, 's');
      const allSeconds = this.endTimeObj.diff(this.nowTimeObj, 'seconds');
      this.setCountDown(allSeconds);// 跳动, 页面计时器读数刷新
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //this.runCountDown()
    //console.log('ngOnChanges  ngOnChangesngOnChangesngOnChanges')
  }

  // 运行计数器检测动态更新
  async runCountDown() {
    // 获得happy hour数据
    // 异步获取，本次获取的都是缓存的内容
    const o = await this.HappyHourService.getHappyHourSetting();
    try {
      if (!o) {
        throw 'error';
      }
      const nowServerTime =  moment(o.serverTime).utc();
      let updateNowTime:boolean = false;
      if( !this.prevewServerTime ){
        this.prevewServerTime =  moment(o.serverTime).utc();
      }
      if( this.prevewServerTime.unix() != nowServerTime.unix()){
        updateNowTime = true;
        this.prevewServerTime = moment(o.serverTime).utc();
      }
      
      if (!this.nowTimeObj || updateNowTime) {
        this.nowTimeObj = moment(o.serverTime).utc();
      }
      if (!this.endTimeObj) {
        this.endTimeObj = moment(o.serverTime).utc();
      }
      if(!this.startTimeObj){
        this.startTimeObj = moment(o.serverTime).utc();
      }
      const startTimeArr = o.startTime.split(':');//['04','46'];//['10','24'];// 
      this.startTimeObj.hour(parseInt(startTimeArr[0]));
      this.startTimeObj.minute(parseInt(startTimeArr[1]));
      this.startTimeObj.seconds("00");

      const endTimeArr = o.endTime.split(':');//['04','46'];//['10','24'];// 
      this.endTimeObj.hour(parseInt(endTimeArr[0]));
      this.endTimeObj.minute(parseInt(endTimeArr[1]));
      this.endTimeObj.seconds("00");
      this.isInited = true; //初始化完成，不代表活动进行哦，此状态表示后续可以进行操作
    } catch (error) {
      return false;
    } finally {}
    return o;
  }

  async ngAfterViewInit() {
    // this.Timer();
    // this.Timer(); // 计算剩余时间
    // 每秒计算一次
    // 这里需要10秒请求一次接口，用来适应提前结束的情况，比如手工取消活动
    // 到时间自动取消
    
    // 首次强制更新
    const o = await this.HappyHourService.getHappyHourSetting(true);
    
    this.TimerHandle = setInterval(() => {
      // 更新数据
      this.runCountDown().then((a) => {
        //action: running运行中, end 结束
        this.isEnabled = a.isEnabled; // 是否开启
        this.isStarted = a.isStarted; // 是否开始
        this.startTime = a.startTime;//开始时间
        this.endTime = a.endTime;//结束时间
        this.serverTime = a.serverTime;//服务器时间
      }).catch(s=>{
        
      });// 初始化计时器

      if (this.isInited) {
        // 跑秒
        this.runTimer();
        this.happyHourStatusChange.emit(this.happyHourStatus);

        let runSecend = this.nowTimeObj.diff(this.startTimeObj, 'seconds');//　已经进行的秒数
        let leftSecend = this.endTimeObj.diff(this.nowTimeObj, 'seconds');//　活动剩余的秒数
        const happyHourSetting = this.StorageServic.read('happyHourSetting');

        if( runSecend > 0 && leftSecend > 0 ){
          this.runing = 'runing'
          // 活动进行中
        }else if( runSecend <=0 ){
          this.runing = 'waitRun'
          // 活动还未开始
        }else{
          this.runing = 'runEnd'
          //活动结束了
        }
        this.setHappyEvent.emit({ now: this.runing, prevew: this.happyHourStatus, items:happyHourSetting.items, t:[runSecend,leftSecend], v:this.nowTimeObj });
      } 
    }, 1000);

    //console.log('HappyHourService', this.TimerHandle)
  }

  StartTimer(){
    this.inShow = true;
  }
  StopTimer(){
    this.inShow = false;
  }
  ngOnDestroy() {
    this.StopTimer();
  }
}
