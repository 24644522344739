import { Injectable } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { StorageServic } from './storage/storage.service';
import { HttpserviceService } from './httpService';


@Injectable({
  providedIn: 'root'
})
export class BranchService {
  constructor(
    private httpService: HttpserviceService,
    private router: Router,
    private NavController: NavController,
    public StorageServic: StorageServic,
    public alertController: AlertController
  ) { }

  // 获取当前分店
  nowBranch() {
    return this.StorageServic.read('BranchInfo');
  }
  setBranch(val) {
    return this.StorageServic.write('BranchInfo', val);
  }
  filterBranch(Ref: string, data: any) {
    let branch: any;
    for (let val in data) {
      if (data[val]['accountRef'] == Ref) {
        branch = data[val];
        break;
      }
    }
    return branch;
  }
  setBranchLisr(val) {
    return this.StorageServic.write('BranchList', val);
  }
  getBranchLisr() {
    return this.StorageServic.read('BranchList');
  }
  // 获取门店列表，如果不存在则从接口取
  async initeBranchListAll() {
    let list = this.StorageServic.read('BranchList');
    if (!list) {
      list = await this.httpService.httpGet("/AppBranch/FindAll", {});
      this.setBranchLisr(list);
    }
  }
  //品牌服务 
  async findAll(location) {
    //显示等待样式
    await this.httpService.showLoading('Waiting please...');
    let loginUserInfo = this.StorageServic.read('loginUserInfo');
    let param = {}

    if (loginUserInfo) {
      param['customerGuid'] = loginUserInfo['Guid'];
    }
    if (location) {
      param['location'] = location;
    }

    return await this.httpService.httpGet("/AppBranch/FindAll" , param).then(async res => {
      await this.httpService.hideLoading();
      return res;
    }).catch(async (x) => {
      await this.httpService.hideLoading();
      (await this.alertController.create({
        cssClass: 'my-custom-class',
        mode: "ios",
        message: 'Please check your connection and try again. ',
        buttons: ['OK']
      })).present();
      this.NavController.navigateForward('/tabs/home');
      //this.NavController.navigateRoot(['/tabs/home']);
      //this.NavController.pop();
      return [];
    });
  }
  async GetStoreSetting(accountRef: string, callback){
    this.httpService.httpGet2("/AppBranch/GetStoreSetting/" + accountRef , {}).then(async (res: any) => {
      callback && callback(res);
    });
  }
  // 获取店铺优惠券 或者 优惠券
  async getOffers(accountRef: string, callback) {
    await this.httpService.showLoading('Waiting please...');
    await this.httpService.httpGet2("/AppVoucher/FindVouchers/" + accountRef, {}).then(async (res: any) => {
      await this.httpService.hideLoading();
      callback && callback(res);
    }).catch(async (x) => {
      await this.httpService.hideLoading();
      (await this.alertController.create({
        cssClass: 'my-custom-class',
        mode: "ios",
        message: 'Please check your connection and try again. ',
        buttons: ['OK']
      })).present();
      return [];
    });
  }
  // 获取单页面信息
  async getContentPage(pageName: string, callback) {
    await this.httpService.showLoading('Waiting please...');
    await this.httpService.httpGet2("/ContentPage/FindPageByName/" + pageName, {}).then(async (res: any) => {
      if (res.length == 0) {
        await this.httpService.hideLoading();
        (await this.alertController.create({
          cssClass: 'my-custom-class',
          mode: "ios",
          message: 'no data',
          buttons: ['OK']
        })).present();
      }
      else {
        await this.httpService.hideLoading();
      }
      callback && callback(res);
    });
  }
  //通过标签获取列表
  async FindPagesByTag(tag: string, callback) {
    await this.httpService.httpGet2("/ContentPage/FindPagesByTag/" + tag, {}).then(async (res: any) => {
      callback && callback(res);
    });
  }
  // 获取信息ByGuide
  async getPageByGuid(guid: string, callback) {
    await this.httpService.httpGet2("/ContentPage/FindPageByGuid/" + guid, {}).then(async (res: any) => {
      callback && callback(res);
    });
  }
  //检查当前guid是否需要popup
  checkisPortGuid(Guid, remember = false) {
    let list: any = this.StorageServic.read('checkisPortGuid');
    let now = +new Date();
    let newList: Array<any> = [];
    let find: boolean = false;
    for (let x in list) {
      if ((list[x]['t'] + 3600 * 24 * 7 * 1000) > now) {
        newList.push({ 'Guid': list[x]['Guid'], 't': list[x]['t'] });
      }
    }
    for (let x in newList) {
      if (list[x]['Guid'] == Guid) {
        find = true;
      }
    }
    if (remember && !find) {//不存在并且强制不显示时加入列表
      newList.push({ 'Guid': Guid, 't': now });
    }
    this.StorageServic.write('checkisPortGuid', newList);
    return find;
  }

  // happy hour活动
  // {accountRef}/{branchRef}
  // 获得，Branch.EnableHappyHour 的属性，如果是true就表示这家店有活动
  async getBranchdata(accountRef: string, branchRef:string) {
    return this.httpService.httpGet2("/branch/getbranchdata/" + accountRef + '/' + branchRef, {});
  }
  // 获得门店活动详情
  // {accountRef}/{branchRef}
  async getLoadHappyHourSetting(accountRef: string, branchRef:string) {
    return this.httpService.httpGet2("/menu/LoadHappyHourSetting/" + accountRef + '/' + branchRef, {});
  }
}

