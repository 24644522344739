import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonMeterComponent } from './ion-meter/ion-meter.component';
import { ProductPickupComponent } from './product-pickup/product-pickup.component';
import { ProductSettingComponent } from './product-setting/product-setting.component';
import { CountdownComponent } from './countdown/countdown.component'
@NgModule({
  declarations: [IonMeterComponent, ProductPickupComponent, ProductSettingComponent, CountdownComponent],
  imports: [
    CommonModule,
    IonicModule,// ionic组件使用
    FormsModule // 双向绑定 [(ngModel)]
  ],
  exports: [IonMeterComponent, ProductPickupComponent, ProductSettingComponent, CountdownComponent]// 暴露组件
})
export class ComponentModule { }
