import { Injectable } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { StorageServic } from './storage/storage.service';
import { HttpserviceService } from './httpService';
import { BranchService } from './branch.service';


@Injectable({
    providedIn: 'root'
})

export class HappyHourService {
    constructor(
        private httpService: HttpserviceService,
        private NavController: NavController,
        public StorageServic: StorageServic,
        public alertController: AlertController,
        public BranchService: BranchService
    ) { }
    private wating:boolean = false;
    private timeout:number = 10;

    // 刷新,活动信息10秒刷新一次
    async getHappyHourSetting(fouce:boolean = false){
        let happyHour:object={};
        let accountRef:string;
        let branchRef:string;
        const Branch = this.BranchService.nowBranch();
        if(!Branch){
            return false;
        }
        // 从storage.Branch获取
        accountRef = Branch.accountRef;
        branchRef = Branch.branchRef;
        if(!accountRef || !branchRef){
            return false;
        }
        // 刷新标记
        let needRefresh = false;
        let data = this.StorageServic.read('happyHourSetting')
        
        // 缓存为空时
        if(!data){
            needRefresh = true;
        }
        // 缓存超时时.10秒钟刷新
        const nowTime = Math.round((+new Date())/1000);
        if(!needRefresh && (nowTime - data.timestep) > this.timeout){
            needRefresh = true;
        }
        if(data && data.request){
            needRefresh = false;
        }
        // 开始异步刷新
        if(fouce || (needRefresh && !this.wating)){
            this.wating = true;
            if(data){
                data.request = true;
                this.StorageServic.write('happyHourSetting', data);
            }
            this.BranchService.getLoadHappyHourSetting(accountRef, branchRef).then((result)=>{
                happyHour['endTime']=result['endTime'];
                happyHour['isEnabled']=result['isEnabled'];
                happyHour['isStarted']=result['isStarted'];
                happyHour['serverTime']=result['serverTime'];
                happyHour['startTime']=result['startTime'];
                happyHour['items']=result['items'];
                happyHour['timestep']=Math.round((+new Date())/1000);
                happyHour['accountRef'] = accountRef;
                happyHour['branchRef'] = branchRef;
                happyHour['request'] = false;
                this.StorageServic.write('happyHourSetting', happyHour);
                this.wating = false;
                if(result['isEnabled'] ){
                    this.timeout = 10;
                }else{
                    this.timeout = 120;
                }
            }).catch(()=>{
                data.request = false;
                this.StorageServic.write('happyHourSetting', data);
            });
        }
        return data;
    }
}