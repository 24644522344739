import { NgModule } from '@angular/core';
import { BrowserModule,HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http'

import { ComponentModule } from './components/component.module';
import { IonicStorageModule } from '@ionic/storage-angular';
//import { IonicStorageModule } from "@ionic/storage";
//import { Geolocation } from '@ionic-native/geolocation/ngx';
//import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

import { Clipboard } from '@ionic-native/clipboard/ngx';

import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [ BrowserModule, IonicModule.forRoot({ mode: 'ios' }), AppRoutingModule, HttpClientModule, IonicStorageModule.forRoot(), ComponentModule, HammerModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Clipboard, LaunchNavigator],
  bootstrap: [AppComponent],
})
export class AppModule { }